




































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { CompetenceType } from '@/store/kode/types'

@Component
/*
@group HSZG
This component is used to display a competence.
It can be used in two ways - via competence ID (delivered from KODE) or via ENUM (list in de.json)
*/
export default class Competence extends Vue {
  // index if used in a series
  @Prop() index: string
  // the competence as ENUM or ID (e.g. DELEGIEREN or 19dd81b6-ba88-46f4-94c3-3628676e1129)
  @Prop() competence: string
  // this has to be true if an ID is used, false for ENUM usage
  @Prop() isId: boolean
  // if the corresponding KODE document (KEP) for the competence can be downloaded
  @Prop() downloadable: boolean

  get competenceObject (): CompetenceType {
    if (this.isId) {
      return Object.values((this.$i18n.t('all-competences'))).filter(c => c.id === this.competence)[0]
    } else {
      return Object.values((this.$i18n.t('all-competences')))[Object.keys(this.$i18n.t('all-competences')).indexOf(this.competence)]
    }
  }

  downloadKEP (): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `${process.env.VUE_APP_ROOT_API}kode/download-kep/${this.competence}`, true)
    if (localStorage.getItem('bearer')) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('bearer') as string))
    }
    xhr.setRequestHeader('Content-type', 'application/octet-stream')
    xhr.responseType = 'arraybuffer'
    xhr.send()
    xhr.onload = function () {
      if (this.status === 200) {
        const blob = new Blob([this.response], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        if (xhr.getResponseHeader('Content-Disposition')) {
          link.download = xhr.getResponseHeader('Content-Disposition')!.split('filename=')[1]
        } else {
          link.download = 'download.pdf'
        }
        link.click()
        link.remove()
      } else {
        self.$root.$emit('alert', self.$i18n.t('warning.internal-error-message').toString(), self.$i18n.t('warning.general-error-message').toString(), true)
      }
    }
  }
}
