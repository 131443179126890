export const downloadPdf = (self: any, uri: string, fileType: string): void => {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const xhr = new XMLHttpRequest()
  xhr.open('GET', `${process.env.VUE_APP_ROOT_API}${uri}/${fileType}`, true)
  if (localStorage.getItem('bearer')) {
    xhr.setRequestHeader('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('bearer') as string))
  }
  xhr.setRequestHeader('Content-type', 'application/octet-stream')
  xhr.responseType = 'arraybuffer'
  xhr.send()
  xhr.onload = function () {
    if (this.status === 200) {
      const blob = new Blob([this.response], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      if (xhr.getResponseHeader('Content-Disposition')) {
        link.download = xhr.getResponseHeader('Content-Disposition')!.split('filename=')[1]
      } else {
        link.download = 'download.pdf'
      }
      link.click()
      link.remove()
    } else {
      self.$root.$emit('alert', self.$i18n.t('warning.internal-error-message').toString(), self.$i18n.t('warning.general-error-message').toString(), true)
    }
  }
}

export const downloadRessource = (self: any, url: string, fileName: string): void => {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  if (localStorage.getItem('bearer')) {
    xhr.setRequestHeader('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('bearer') as string))
  }
  xhr.setRequestHeader('Content-type', 'application/octet-stream')
  xhr.responseType = 'arraybuffer'
  xhr.send()
  xhr.onload = function () {
    if (this.status === 200) {
      const blob = new Blob([this.response], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      link.remove()
    } else {
      self.$root.$emit('alert', self.$i18n.t('warning.internal-error-message').toString(), self.$i18n.t('warning.general-error-message').toString(), true)
    }
  }
}
