





























































































import { Component } from 'vue-property-decorator'
import InfoBox from '@/components/hszg/InfoBox.vue'
import SelectedCompetences from '@/components/kode/SelectedCompetences.vue'
import { CompetenceImprovement, KodeStatusDTO } from '@/store/kode/types'
import { Action } from 'vuex-class'
import { GET_PROFILE_KODE_STATUS } from '@/store/user/actions.type'
import { CompetenceProfileType, SimpleServerResponse } from '@/store/types'
import { GET_COMPETENCES_TO_IMPROVE_OWN } from '@/store/kode/actions'
import { GET_OWN_STUDENT_COMPETENCE_PROFILE } from '@/store/student/actions'
import { downloadPdf } from '@/common/download'
import MetaComponent from '@/common/MetaComponent.vue'
@Component({
  components: { SelectedCompetences, InfoBox }
})
export default class Documents extends MetaComponent {
  @Action(GET_PROFILE_KODE_STATUS)
  public getProfileKodeStatus: () => Promise<KodeStatusDTO>

  @Action(GET_COMPETENCES_TO_IMPROVE_OWN)
  public getCompetencesToImproveOwn: () => Promise<SimpleServerResponse>

  @Action(GET_OWN_STUDENT_COMPETENCE_PROFILE)
  public getOwnStudentCompetenceProfile: () => Promise<SimpleServerResponse>

  private jobFamilySelected = false

  kodeStatus = ''

  improvementData: CompetenceImprovement = {}
  competenceProfile: CompetenceProfileType = {}

  loadKodeStatusAndCompetencesForImprovement (): void {
    const promises = 3
    let finishedPromises = 0
    this.$root.$emit('load')
    this.getProfileKodeStatus().then(data => {
      this.kodeStatus = data.kodeStatus

      this.getCompetencesToImproveOwn().then(data => {
        if (data.content) {
          this.improvementData = data.content
          if (this.improvementData.jobFamilyId) this.jobFamilySelected = true
        }
      }).finally(() => {
        finishedPromises++
        if (finishedPromises === promises) {
          this.$root.$emit('end-load')
        }
      })

      this.getOwnStudentCompetenceProfile().then(data => {
        this.competenceProfile = data.content
      }).finally(() => {
        finishedPromises++
        if (finishedPromises === promises) {
          this.$root.$emit('end-load')
        }
      })
    }).catch(() => {
      this.$root.$emit('end-load')
    }).finally(() => {
      finishedPromises++
      if (finishedPromises === promises) {
        this.$root.$emit('end-load')
      }
    })
  }

  downloadPdf (uri: string, fileType: string): void {
    downloadPdf(this, uri, fileType)
  }

  created (): void {
    this.loadKodeStatusAndCompetencesForImprovement()
  }
}
